<template>
  <div class="h-full">
    <AppMenu
      v-if="isAuthenticated"
      class="!right-2 origin-top-right !top-[calc(100%+0.5rem)] !min-w-[220px] !z-[62]"
      container-class="h-full py-2"
      :items="[
        {
          text: $t('account.savedProperties'),
          to: localePath(
            `/${$t('routes.account')}/${$t('routes.savedProperties')}`
          )
        },
        {
          text: $t('account.savedSearches'),
          to: localePath(
            `/${$t('routes.account')}/${$t('routes.savedSearches')}`
          )
        },
        {
          text: $t('account.accountSettings'),
          to: localePath(`/${$t('routes.account')}`)
        },
        { divider: true },
        {
          text: $t('auth.signOut'),
          action: close => closeAndSignOut(close)
        }
      ]"
      @update:open="e => (showAuthMenu = e)"
    >
      <slot name="logged-in-button">
        <AppButton
          class="icon-button variant-invisible h-full aspect-square !p-0 text-white"
        >
          <div
            class="w-[28px] h-[28px] rounded-full bg-primary text-white font-bold text-lg pt-0.5 font-[arial]"
          >
            {{ avatarLetter }}
          </div>
        </AppButton>
      </slot>
    </AppMenu>
    <template v-else>
      <div
        id="dropdown-container"
        class="max-md:hidden h-full py-2"
        :class="{
          '!hidden': isAuthenticated
        }"
      >
        <VDropdown
          id="dropdown-container"
          placement="bottom-end"
          :distance="0"
          container="#dropdown-container"
          class="login-button-container h-[calc(100%+24px)] -m-[12px] border-[12px] border-transparent"
          popper-class="reset-dropdown"
          @update:shown="v => (showAuthPopperMenu = v)"
        >
          <slot name="login-button">
            <AppButton
              class="icon-button variant-invisible w-full h-full aspect-square !p-0 text-white"
              :icon="mdiAccountCircleOutline"
              :aria-label="$t('common.accountMenu')"
            />
          </slot>
          <template #popper="{ hide }">
            <div class="dropdown-menu max-h-[calc(100svh-100px)] mx-4">
              <SiteAuth
                v-if="enableAuth"
                closable
                @close="() => closeAuthPopperMenu(hide)"
              />
              <div
                v-else
                class="flex items-center justify-center h-12 w-[240px] mx-auto"
              >
                <Icon
                  :path="mdiLoading"
                  class="text-neutral-light h-5 w-5 animate-spin"
                />
              </div>
            </div>
          </template>
        </VDropdown>
      </div>
      <div
        v-if="!isAuthenticated"
        class="login-button-container md:hidden h-full p-2 -ml-1 max-[320px]:-ml-3"
      >
        <slot name="login-button">
          <AppButton
            class="icon-button variant-invisible w-full h-full aspect-square !p-0 text-white"
            :icon="mdiAccountCircleOutline"
            :aria-label="$t('common.accountMenu')"
          />
        </slot>
      </div>
    </template>
    <!-- auth modal -->
    <Transition
      enter-active-class="transition duration-[0.21] ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-[0.1] ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showAuth && !isAuthenticated"
        class="md:hidden bg-white fixed inset-0 z-[62] w-full"
      >
        <SiteAuth
          v-if="enableAuth"
          class="!min-w-[240px]"
          closable
          @close="closeAuthMenu"
        />
        <div
          v-else
          class="flex items-center justify-center h-12 w-[240px] mx-auto"
        >
          <Icon
            :path="mdiLoading"
            class="text-neutral-light h-5 w-5 animate-spin"
          />
        </div>
      </div>
    </Transition>
    <SiteAuth v-if="enableAuth" ref="authComponent" class="!hidden" />
  </div>
</template>

<script setup lang="ts">
import { mdiAccountCircleOutline } from '@mdi/js';
import type { User } from '@nhost/vue';
import { Dropdown as VDropdown } from 'floating-vue';

import 'floating-vue/dist/style.css';

const AppMenu = defineAsyncComponent(
  () => import('@/async-components/App/Menu.vue')
);
const SiteAuth = defineAsyncComponent(
  () => import('@/async-components/Site/Auth.vue')
);

const emit = defineEmits(['update:open']);

const localePath = useLocalePath();

const user = inject('user', null) as Ref<User | null> | null;
const isAuthenticated = computed(() => Boolean(user?.value?.email));

const avatarLetter = computed(() => {
  if (user.value?.email || user.value?.displayName) {
    return (user.value.email || user.value.displayName)[0].toUpperCase() || 'U';
  }

  return 'U';
});

watch(isAuthenticated, () => {
  if (isAuthenticated.value) {
    // fixes modal popping up on mobile when signing out
    showAuth.value = false;
  }
});

const showAuth = ref(false);

const showAuthMenu = ref(false); // readonly
const showAuthPopperMenu = ref(false); // readonly

const authComponent = ref<{
  signOut: () => Promise<void>;
}>();

watch(
  () => [showAuth.value, showAuthMenu.value],
  () => {
    emit('update:open', showAuth.value || showAuthMenu.value);
  }
);

async function closeAndSignOut(close: () => void) {
  close();
  await new Promise(resolve => setTimeout(resolve, 300));
  await authComponent.value.signOut();
}

function closeAuthMenu() {
  showAuth.value = false;
}

function closeAuthPopperMenu(close: () => void) {
  showAuthPopperMenu.value = false;
  close();
}

const enableAuth = inject('enableAuth', ref(false)) as Ref<boolean> | null;

function injectEvents() {
  /*
  
          @click="showAuth = !showAuth"
          @mouseenter="enableAuth = true"

          to all buttons child of login-button-container elements
  */

  const loginButtonContainers = document.querySelectorAll(
    '.login-button-container'
  );

  if (!loginButtonContainers?.length) {
    return;
  }

  loginButtonContainers.forEach(container => {
    const loginButton = container.querySelector('button, [role="button"]');

    if (loginButton) {
      loginButton.addEventListener('click', () => {
        showAuth.value = !showAuth.value;
      });

      const enableAuthCallback = () => {
        enableAuth.value = true;
        loginButton.removeEventListener('mouseenter', enableAuthCallback);
      };

      loginButton.addEventListener('mouseenter', enableAuthCallback);
    }
  });
}

watch(isAuthenticated, injectEvents);

onMounted(() => {
  if (!enableAuth.value) {
    if (!window.apolloClient) {
      setTimeout(() => {
        enableAuth.value = true;
      }, 6500);
    } else {
      enableAuth.value = true;
    }
  }
  injectEvents();
});
</script>

<style lang="scss"></style>
